export default {
  project: "Countdown",
  name: "Countdown",
  url: "http:localhost:3000",
  auth_landing_module: '/ordering',
  idle_limit: 1800000,
  thor: {
    //test
    // moduleCode: "IjFDYT22DHvwesvs",

    // live
    moduleCode: "gLBs36QsZ",
    // programId: null,
// dev//
    // memberStatus: {
    //   development: {
    //     "4672": "Registered",
    //     "4676": "Unregistered",
    //     "4677": "Pending",
    //     "4678": "OnHold",
    //     "4673": "Terminated"
    //   },
    //   production: {
    //     "4672": "Registered",
    //     "4676": "Unregistered",
    //     "4677": "Pending",
    //     "4678": "OnHold",
    //     "4673": "Terminated"
    //   }
    // }

    //prod//
    memberStatus: {
      development: {
        "2141": "Registered",
        "2143": "Unregistered",
        "2144": "Pending",
        "2145": "OnHold",
        "2142": "Terminated"
      },
      production: {
        "2141": "Registered",
        "2143": "Unregistered",
        "2144": "Pending",
        "2145": "OnHold",
        "2142": "Terminated"
      },
    }
  },
  api: {
    countdown_v1: {
      url: {
        // development: "https://countdown-uat.txweb.site",
        // production: "https://countdown-uat.txweb.site",

        // production: "https://countdown-uat.tr4ns.com",
        // development: "https://countdown-uat.tr4ns.com",
        // captcha:"https://countdown-uat.tr4ns.com"
        
        development: "https://countdown.tranxactor.com",
        production: "https://countdown.tranxactor.com",
        captcha:"https://countdown.tranxactor.com"
        
        // development: "https://countdown-oci.tranxactor.com",
        // production: "https://countdown-oci.tranxactor.com"
        
      }
    }
  }
}

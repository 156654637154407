import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react-lite'
import AccountFooter from '../../app-module-account-footer/default';
import TandC from '../../app-module-account-tnc/default';
/* mui */
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
/* mui */

/* packages */
import { Inputs, Buttons, Invalid } from '../../lib-atomic';
import { FormHook, LocalStorage } from '../../lib-atomic-functions';
/* packages */

/* sections */
import AddressFinderWidget1 from '../../app-module-account-address-finder/default/finder1';
import AddressFinderWidget2 from '../../app-module-account-address-finder/default/finder2';
/* sections */

/* functions  */
import { AccountRegister } from '../../app-module-account-container/functions'
/* functions  */

import CONFIG from '../config';
import color from '@material-ui/core/colors/pink';

/* CONSTANTS */
const ACCOUNTOBJECTS = CONFIG.application.stepTwo.objects;
const REGISTERFAILRESPONSE = CONFIG.application.stepTwo.responses.fail;
/* CONSTANTS */

const account = observer(({props, handleNext, handleBack}) => {

  /* state */
  const { accountRegisterContext, accountRegisterMethod } = AccountRegister({CONFIG});
  /* state */

  const [isPostal, setPostal] = useState(false);
  const [isPhysical, setPhysical] = useState(false);
  const [isTNC, setTNC] = useState(false);
  const [isPostalManual, setPostalManual] = useState(false);
  const [isPhysicalManual, setPhysicalManual] = useState(false);
  const [isPostalDifferent, setPostalDifferent] = useState(false);


  //for dev env
  // const titleOption = [
  //   { name: "Dr", value: "246" },
  //   { name: "Mx", value: "245" },
  //   { name: "Miss", value: "244" },
  //   { name: "Ms", value: "243" },
  //   { name: "Mrs", value: "242" },
  //   { name: "Mr", value: "241" }
  // ];


  //for prod env
  const titleOption = [
    { name: "Dr", value: "289" },
    { name: "Mx", value: "288" },
    { name: "Miss", value: "287" },
    { name: "Ms", value: "286" },
    { name: "Mrs", value: "285" },
    { name: "Mr", value: "284" }
  ];
  const genderOption = [
    { name: "M", value: "1" },
    { name: "F", value: "2" },
    { name: "X", value: "3" }
  ]
  const homePhoneOption = [
    { name: "", value: "" },
    { name: "03", value: "03" },
    { name: "04", value: "04" },
    { name: "06", value: "06" },
    { name: "07", value: "07" },
    { name: "09", value: "09" }
  ]
  const mobilePhoneOption = [
    { name: "", value: "" },
    { name: "020", value: "020" },
    { name: "021", value: "021" },
    { name: "022", value: "022" },
    { name: "025", value: "025" },
    { name: "027", value: "027" },
    { name: "028", value: "028" },
    { name: "029", value: "029" }
  ]

  /* intial values, options */
  ACCOUNTOBJECTS.titleId.options = titleOption;
  ACCOUNTOBJECTS.genderId.options = genderOption;
  ACCOUNTOBJECTS.prefixMobileNumber.options = mobilePhoneOption;
  ACCOUNTOBJECTS.prefixPhone2.options = homePhoneOption;
  /* intial values, options */
  const errorMessage =[];
  const handleRegistration = async () => {
    
    
    const { history } = props;
    const { login } = LocalStorage.getLS();
  
    values.card = login.username;

    if (values.physicalPostCode==""&&values.physicalCity==""){
      LocalStorage.addLS('physicaladdress', "Please enter a physical address");
   
      return false;
    }

    if (  values.address1 == "" || values.postcode == ""){

      values.address1 = " ";
      values.address2 = " ";
      values.suburb = " ";
      values.city = " ";
      values.postcode = " ";
    }
   
    if (values.mobileNumber==""&&values.phone2==""){
      LocalStorage.addLS('error11', "Please enter at least one phone number");
      errorMessage.push("Please enter at least one phone number");
    //  console.log("errorMessage",errorMessage);
     return false;
    }
    if (values.prefixMobileNumber==""&&values.prefixPhone2==""){
     
      LocalStorage.addLS('error11', "Please enter valid phone number");
      return false;
    }
     //validation for prefix number
    if (values.prefixMobileNumber!=""||values.prefixPhone2!=""){
     
      LocalStorage.addLS('error11', "");
    }
    //validation for phone number
    if (values.mobileNumber!=""||values.phone2!=""){
     
      LocalStorage.addLS('error11', "");
    }
    if (values.mobileNumber!=""&&values.phone2!=""){
      if(values.prefixMobileNumber==""){
        LocalStorage.addLS('error11', "Please enter valid phone number");
        return false;
      }if (values.prefixPhone2==""){
        LocalStorage.addLS('error11', "Please enter valid phone number");
        return false;
      }else{
        LocalStorage.addLS('error11', "");
      }
    }
 //validation for phone number

   if( values.dob.length < 15){
     values.dob = values.dob + "T00:00:00Z";
   }
  
    // values.dob = values.dob + "T00:00:00Z";
    values.flOptin= values.flOptin ? 1 : 0;
    
    
    // return false;
    await accountRegisterMethod({body: JSON.stringify(values)});
   
    if (accountRegisterContext.data.invalidToken) return false;
    LocalStorage.removeLS(); // when registration is successfull remove localstorages
    LocalStorage.addLS('emailAddress',  values.emailAddress );
    props.history.push('/u/landing-registration-send-activation');
  }

  // const handleTest = async () => {
  //   const { history } = props;
  //   const sample = {"card": "6276046727399450", "pin":"","pin_confirm":"","titleId":"244","firstName":"Jayson",
  //     "lastName":"Cantones","dob":"2019-08-15T00:00:00Z","genderId":"1",
  //     "emailAddress":"nipchinkdog@gmail.com","mobileNumber":"0210578606","home":"",
  //     "address1":"Suite 11C","address2":"11 Mount Street","suburb":"Auckland Central","city":"Auckland","postcode":"1010","physicalAddress1":"","physicalAddress2":"","physicalSuburb":"","physicalCity":"","physicalPostCode":""
  //     ,"oneCard":"888"
  //     ,"flOptin": 1}
  //   await accountRegisterMethod({body: JSON.stringify(sample)});
  //   if (accountRegisterContext.data.invalidToken) return false;
  //   LocalStorage.removeLS(); // when registration is successfull remove localstorages
  //   LocalStorage.addLS('login', { emailAddress: "jayson.cantones@tranxactor.com" });
  //   props.history.push('/u/landing-registration-send-activation');
  // }

  const handleBackLS = () => {
    LocalStorage.removeLS(); // everytime back remove localstorages
    handleBack();
  }

  const handleTNC = (event) => {
  
    if(values.physicalSuburb === ""){
      var suburb1 = "None";
    }else{
      suburb1= values.physicalSuburb;
    }
    setTNC(event.target.checked);
    if (!isPostalDifferent) {
     
    
      // values.address1 = values.physicalAddress1;
      // values.address2 = values.physicalAddress2; 
      // values.suburb =  suburb1;
      // values.city = values.physicalCity;
      // values.postcode = values.physicalPostCode;
    }
  }

  const handlePostalManual = (event) => {
    setPostalManual(event.target.checked);
    setPostal(false);
    values.address1 = "";
    values.address2 = "";
    values.suburb = "";
    values.city = "";
    values.postcode = "";
    ACCOUNTOBJECTS.address1.disabled = !event.target.checked;
    ACCOUNTOBJECTS.address2.disabled = !event.target.checked;
    ACCOUNTOBJECTS.suburb.disabled = !event.target.checked;
    ACCOUNTOBJECTS.city.disabled = !event.target.checked;
    ACCOUNTOBJECTS.postcode.disabled = !event.target.checked;
  }

  const handlePhysicalManual = (event) => {
    setPhysicalManual(event.target.checked);
    setPhysical(false);
    values.physicalAddress1 = "";
    values.physicalAddress2 = "";
    values.physicalSuburb = "";
    values.physicalCity = "";
    values.physicalPostCode = "";
    ACCOUNTOBJECTS.physicalAddress1.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalAddress2.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalSuburb.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalCity.disabled = !event.target.checked;
    ACCOUNTOBJECTS.physicalPostCode.disabled = !event.target.checked;
  }

  const pinCheck = (event) => {
    console.log("Event",event)
  }

  const handlePostalDifferent = (event) => {
    setPostalDifferent(event.target.checked);
    if (!event.target.checked) {
      // values.address1 = values.physicalAddress1;
      // values.address2 = values.physicalAddress2;
      // values.suburb =  values.physicalSuburb;
      // values.city = values.physicalCity;
      // values.postcode = values.physicalPostCode;
    } else {
      values.address1 = "";
      values.address2 = "";
      values.suburb = "";
      values.city = "";
      values.postcode = "";
    }
  }

  const handlePostalAddress = (selected) => {
    setValues(values => ({
      ...values,
      address1: selected.address_line_1(),
      address2: selected.address_line_2(),
      suburb: selected.suburb(),
      city: selected.city(),
      postcode: selected.postcode()
    }));
    setPostal(true);
  }

  const handlePhysicalAddress = (selected) => {
    
    setValues(values => ({
      ...values,
      physicalAddress1: selected.address_line_1(),
      physicalAddress2: selected.address_line_2(),
      physicalSuburb: selected.suburb(),
      physicalCity: selected.city(),
      physicalPostCode: selected.postcode(),
     
    }));
    setPhysical(true);
  }

  const {
    values,
    setValues,
    onChange,
    onCheck,
    onPick,
    onSubmit,
    error,
  } = FormHook.useFormHook(handleRegistration, ACCOUNTOBJECTS);

  const onBlur = (event) => {
    if (!isPostalDifferent) {
      values.address1 = values.address1;
      values.address2 = values.address2;
      values.suburb =  values.suburb;
      values.city = values.city;
      values.postcode = values.postcode;
    }
  }

  const pin = { ...ACCOUNTOBJECTS.pin, error: error.pin, value: values.pin, onChange , autoComplete: "new-password"};
  const pin_confirm = { ...ACCOUNTOBJECTS.pin_confirm, error: error.pin_confirm, value: values.pin_confirm, onChange };
  const password = { ...ACCOUNTOBJECTS.password, error: error.password, value: values.password, onChange , autoComplete: "new-password"};
  const password_confirm = { ...ACCOUNTOBJECTS.password_confirm, error: error.password_confirm, value: values.password_confirm, onChange };
  const titleId = { ...ACCOUNTOBJECTS.titleId, error: error.titleId, value: values.titleId, onChange };
  const firstName = { ...ACCOUNTOBJECTS.firstName, error: error.firstName, value: values.firstName, onChange };
  const lastName = { ...ACCOUNTOBJECTS.lastName, error: error.lastName, value: values.lastName, onChange };
  const dob = { ...ACCOUNTOBJECTS.dob, error: error.dob, value: values.dob, onPick,  };
  const genderId = { ...ACCOUNTOBJECTS.genderId, error: error.genderId, value: values.genderId, onChange };
  const emailAddress = { ...ACCOUNTOBJECTS.emailAddress, error: error.emailAddress, value: values.emailAddress, onChange , autoComplete: "new-username"};
  const mobileNumber = { ...ACCOUNTOBJECTS.mobileNumber, error: error.mobileNumber, value: values.mobileNumber, onChange, autoComplete: "new-mobilenumber" };
  const home = { ...ACCOUNTOBJECTS.phone2, error: error.phone2, value: values.phone2, onChange , autoComplete: "new-number"};
  const pre_mobileNumber = { ...ACCOUNTOBJECTS.prefixMobileNumber, error: error.prefixMobileNumber, value: values.prefixMobileNumber, onChange };
  const pre_home = { ...ACCOUNTOBJECTS.prefixPhone2, error: error.prefixPhone2, value: values.prefixPhone2, onChange };
  const address1 = { ...ACCOUNTOBJECTS.address1, error: error.address1, value: values.address1, onChange };
  const address2 = { ...ACCOUNTOBJECTS.address2, error: error.address2, value: values.address2, onChange };
  const suburb = { ...ACCOUNTOBJECTS.suburb, error: error.suburb, value: values.suburb, onChange };
  const city = { ...ACCOUNTOBJECTS.city, error: error.city, value: values.city, onChange };
  const postcode = { ...ACCOUNTOBJECTS.postcode, error: error.postcode, value: values.postcode, onChange };
  const physicalAddress1 = { ...ACCOUNTOBJECTS.physicalAddress1, error: error.physicalAddress1, value: values.physicalAddress1, onChange, onBlur };
  const physicalAddress2 = { ...ACCOUNTOBJECTS.physicalAddress2, error: error.physicalAddress2, value: values.physicalAddress2, onChange, onBlur };
  const physicalSuburb = { ...ACCOUNTOBJECTS.physicalSuburb, error: error.physicalSuburb, value: values.physicalSuburb, onChange, onBlur };
  const physicalCity = { ...ACCOUNTOBJECTS.physicalCity, error: error.physicalCity, value: values.physicalCity, onChange, onBlur };
  const physicalPostCode = { ...ACCOUNTOBJECTS.physicalPostCode, error: error.physicalPostCode, value: values.physicalPostCode, onChange, onBlur };
  const oneCard = { ...ACCOUNTOBJECTS.oneCard, error: error.oneCard, value: values.oneCard, onChange };
  const flOptin = { ...ACCOUNTOBJECTS.flOptin, error: error.flOptin, value: values.flOptin, onChange: onCheck };
  const tnc = { name: "tnc", initial: "", label: "", helperText: false, rules: false, error: error.tnc, value: values.tnc, onChange: handleTNC };
  const physicalmanual = { name: "physicalmanual", initial: "", label: "I want to enter my address manually", helperText: false, rules: false, error: error.physicalmanual, value: values.physicalmanual, onChange: handlePhysicalManual };
  const postalmanual = { name: "postalmanual", initial: "", label: "I want to enter my address manually", helperText: false, rules: false, error: error.postalmanual, value: values.postalmanual, onChange: handlePostalManual };
  const postaldifferent = { name: "postaldifferent", initial: "", label: "My postal address is different", helperText: false, rules: false, error: error.postaldifferent, value: values.postaldifferent, onChange: handlePostalDifferent };
  const submitButton = { fullWidth: true }
  const backButton = { fullWidth: true, onClick: handleBackLS }

  /* registration responses */
  const errorResponse = [];
 

  if (accountRegisterContext.data.invalidError) {

    if(accountRegisterContext.data.invalidError.error=="Bad Request"){
      errorResponse.push("Please enter your date of birth");
    }
    if (accountRegisterContext.data.invalidError.outcomeCode) {
      const { outcomeDeveloperMessage } = accountRegisterContext.data.invalidError;
      if (outcomeDeveloperMessage === "Email already exists in the program") {
        errorResponse.push("Email already exist");
      }
      
      // else if (outcomeDeveloperMessage === "One Card Number is not unique.") {
      //   errorResponse.push("Onecard Number already exist.");
      // }
      else if (outcomeDeveloperMessage === "User already have active token.") {
        errorResponse.push("Card number already in used.");
      }
      else if (outcomeDeveloperMessage === "Invalid email address") {
        errorResponse.push("Please enter valid email address");
      }
      else if (outcomeDeveloperMessage === "Request has been unsuccessful please contact support.") {
        errorResponse.push("Registration has been unsuccessful please contact support.")
      }
      else {
        errorResponse.push("Registration has been unsuccessful please contact support.")
      }
    }
  }
  /* registration responses */

  /* data props */
  const postalAddressData = { handle: handlePostalAddress, props }
  const physicalAddressData = { handle: handlePhysicalAddress, props }
  const failRegister = { error: errorResponse }

  /* check address error */
  const checkPhysicalAddress = ["physicalAddress1", "physicalCity", "physicalPostCode", "physicalSuburb"]
  const isPhysicalAddressExist = checkPhysicalAddress.filter(data => data in error)[0] ? true : false;
  const failPhysicalAddress = { error: ["Please enter your Address"] }
 
  
  /* data props */
  const {error11, physicaladdress} = LocalStorage.getLS();
  
  return <form onSubmit={onSubmit} noValidate>

    {/* <p onClick={handleTest}>TESTING CLICK</p> */}
    <Typography style={{fontFamily: 'Fresh sans,Fresh,Arial,Helvetica,sans-serif',fontSize: "26px"}}  variant="h4" align="center" gutterBottom>
    <b>My details</b>
  </Typography>
  <br/>
  { error11 &&
      <Fragment>
       <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif', color:"red"}} variant="body2" gutterBottom>
    {error11}
    </Typography>
      </Fragment>
    }
 
    { accountRegisterContext.data.invalidError &&
      <Fragment>
      <Invalid {...failRegister} />
      <br/>
      </Fragment>
    }
    <Inputs.Select {...titleId} />
    <Inputs.Text {...firstName}  inputProps={{maxLength: 30,}}/>
    <Inputs.Text {...lastName} inputProps={{maxLength: 30,}}/>
    <Inputs.Date {...dob} />
    <Inputs.Select {...genderId} />
    <Inputs.Text {...emailAddress} />

    <br/>
    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      <b>Please enter at least one phone number*</b>
    </Typography>
    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Home phone
    </Typography>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Select {...pre_home} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Inputs.Text {...home}  inputProps={{maxLength: 7}}/>
      </Grid>
    </Grid>
    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Mobile
    </Typography>
    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Select {...pre_mobileNumber} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Inputs.Text {...mobileNumber}   inputProps={{maxLength: 10, minLength:6}}/>
      </Grid>
    </Grid>

    <br/>

    {/* physical address */}
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Physical address*
    </Typography>
    { physicaladdress &&
      <Fragment>
       <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif', color:"red"}} variant="body2" gutterBottom>
    {physicaladdress}
    </Typography>
      </Fragment>
    }

    { isPhysicalAddressExist &&
      <Fragment>
      <Invalid {...failPhysicalAddress} />
      </Fragment>
    }

    { !isPhysicalManual &&
    <AddressFinderWidget2 {...physicalAddressData} />
    }

    <Inputs.Checkbox {...physicalmanual} />
    <br/>
    

    { isPhysicalManual &&
    <Fragment>
    <Inputs.Text {...physicalAddress1} />
    <Inputs.Text {...physicalAddress2} />
    <Inputs.Text {...physicalSuburb} />
    <Inputs.Text {...physicalCity} />
    <Inputs.Text {...physicalPostCode} />
    <br/>
    <br/>
    </Fragment>
    }

    { isPhysical &&
    <Fragment>
    <Inputs.Text {...physicalAddress1} />
    <Inputs.Text {...physicalAddress2} />
    <Inputs.Text {...physicalSuburb} />
    <Inputs.Text {...physicalCity} />
    <Inputs.Text {...physicalPostCode} />
    <br/>
    <br/>
    </Fragment>
    }
<div style={{ paddingBottom: "10px", borderBottom: "1px solid #949B9F"}}></div>
    {/* end: physical address */}
    <Inputs.Checkbox {...postaldifferent} />
    {/* postal address */}
    { isPostalDifferent &&
    <Fragment>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      Postal address
    </Typography>

    { !isPostalManual &&
    <AddressFinderWidget1 {...postalAddressData} />
    }

    <Inputs.Checkbox {...postalmanual} />

    <br/>
    <br/>

    { isPostalManual &&
    <Fragment>
    <Inputs.Text {...address1} />
    <Inputs.Text {...address2} />
    <Inputs.Text {...suburb} />
    <Inputs.Text {...city} />
    <Inputs.Text {...postcode} />
    <br/>
    <br/>
    </Fragment>
    }

    { isPostal &&
    <Fragment>
    <Inputs.Text {...address1} />
    <Inputs.Text {...address2} />
    <Inputs.Text {...suburb} />
    <Inputs.Text {...city} />
    <Inputs.Text {...postcode} />
    <br/>
    <br/>
    </Fragment>
    }
    </Fragment>
    }
    {/* end: postal address */}
    <div style={{ paddingBottom: "10px", borderBottom: "1px solid #949B9F"}}></div>

    {/* <Inputs.Text {...oneCard} inputProps={{maxLength: 13,}}/> */}
    <br/><br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
    <b>Set New PIN</b>   </Typography>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
    For added security, please enter a new 4 digit pin for your Christmas Club card.<b>This pin will be used to transact in your local Woolworths store.</b>
        </Typography>
       
    <Inputs.Password {...pin}  inputProps={{maxLength: 4,}}/> <br/> <br/>
    <Inputs.Password {...pin_confirm} inputProps={{maxLength: 4,}}/>
    <br/>
    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
   <b>Create Password</b> 
    </Typography>
    
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
       Please create a secure password to log in to the Christmas Club website in the future. Your password must be at least 8 to 25 characters long and adhere to the following
      </Typography>
      <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
      •	Please include at least one uppercase letter (A to Z)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include as least one lowercase letter (a to z)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include at least one digit (0 to 9)
        </Typography>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
        •	Please include at least one special character
        </Typography>

    <Inputs.Password {...password}  inputProps={{maxLength: 25,}}/><br/> <br/>
    <Inputs.Password {...password_confirm} inputProps={{maxLength: 25,}}/>

    <br/>
    <br/>

    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Checkbox {...flOptin} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
          I would like to receive emails from Woolworths advising me of new offers or services related to Christmas Club.
        </Typography>
      </Grid>
    </Grid>

    <br/>

    <Grid container wrap="nowrap" spacing={2}>
      <Grid item>
        <Inputs.Checkbox {...tnc} />
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>
          I agree to the <a target="_blank" href="https://www.woolworths.co.nz/info/terms-and-conditions/christmas-club">Terms and conditions</a> of the Woolworths Christmas Club. *
        </Typography>
      </Grid>
    </Grid>
<div style={{ paddingBottom: "10px", borderBottom: "1px solid #949B9F"}}></div>
    <br/>
    <Typography style={{fontFamily:'open_sansregular,Helvetica,Arial,sans-serif'}} variant="body2" gutterBottom>*Mandatory field </Typography>
    {/* {error&&<div style={{color:"red"}}> <span>{error.titleId}</span><br/><span>{error.firstName}</span><br/><span>{error.lastName}</span><br/><span>{error.dob}</span><br/><span>{error.emailAddress}</span><br/><span>{error.mobileNumber}</span><br/><span>{error.oneCard}</span></div>} */}

    { isTNC &&
    <Buttons.Normal {...submitButton}>Register</Buttons.Normal>
    }

    <div>&nbsp;</div>
    <Buttons.Text {...backButton}>Back</Buttons.Text>

    <br/>
    <br/>
    <Typography variant="caption" gutterBottom>
      <Link style={{fontFamily: 'open_sansregular,Helvetica,Arial,sans-serif'}} to="/u/login">Already have an account? Login in here.</Link>
    </Typography>
  <br/>
  </form>
   

})


export default account;
